import React from "react"
import Layout from "../../components/layout"
import SEO from "../../components/layout/seo"

import MainImg from "../../img/whereEL.jpg"

function Elevator() {
  return (
    <Layout>
      <SEO title="M2MTech" />
      <div className="fobWrap elevWrap">
        <p className="fobMainImg escalMainImg">
          <img src={MainImg} alt="" />
        </p>
        <div className="sectionWrap fobSectionWrap elevOverview">
          <div className="textWrap">
            <h2>엘리베이터</h2>
            <p className="bodyText">
              승강기 업계의 유지관리 현황은 매우 위태롭습니다. 법적 규정에 따른
              자체점검, 정기검사, 자체정밀검사 등 수 많은 점검은 물론 사용자의
              요청에 따른 긴급호출 서비스까지 많은 일들을 적은 유지보수 인력이
              감당하기가 매우 어려운 실정입니다. 그렇다고 엔지니어를 늘리기엔
              기업운영의 어려움이 따따라 형식적인 유지관리가 이루어지며 최근
              계속해서 많은 사고들이 발생하고 있습니다. <br />
              <br />
              따라서, 정부에서는 최신 원격감시 기술을 적용한 현장에 대해서는
              점검주기를 다소 여유롭게 주고 집중적인 유지관리를 할 수 있게
              정책을 마련하였습니다. <br />
              ELSA Platform은 이러한 부분을 지원해줄 뿐만 아니라 승강기
              유지관리에 필요한 다양한 기능들을 제공하고있습니다. 단위 승강기
              관리를 넘어서 전체 승강기에 대한 자재 및 스케줄링을 유기적으로
              관리하고 업무의 효율을 높여보세요.
            </p>
          </div>
        </div>
      </div>
    </Layout>
  )
}
export default Elevator
